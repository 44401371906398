import { _axios } from '@/plugins/axios.js'

const apiUrl = {
  solutionLabel: '/solution/label', // 解决方案label标签
  solutionList: '/solution/related_solutions', // 解决方案列表
  solutionDetail: '/solution/solution_detail', // 解决方案详情
  solutionContact: '/solution/solution_contact_data', // 解决方案contact弹窗
  countrySelect: '/user/region', // 解决方案contact弹窗 国家下拉选项
  contactSubmit: '/solution/solution_contact', // 解决方案contact弹窗 提交
  infoCenterHit: '/solution/info_center_hit' // 解决方案info center下载次数
}

// 解决方案label标签
export const solutionLabelApi = (params) => _axios.get(apiUrl.solutionLabel + `?type=${params.type ? params.type : 1}`)
// 解决方案列表
export const solutionListApi = (params) => _axios.post(apiUrl.solutionList, params)
// 解决方案详情
export const solutionDetailApi = (params) => _axios.get(apiUrl.solutionDetail + `?solution_id=${params.solution_id}&menu=${params.menu}`)
// 解决方案contact弹窗
export const solutionContactApi = (params) => _axios.post(apiUrl.solutionContact, params)
// 解决方案contact弹窗 国家下拉选项
export const countrySelectApi = () => _axios.get(apiUrl.countrySelect)
// 解决方案contact弹窗 提交
export const contactSubmitApi = (params) => _axios.post(apiUrl.contactSubmit, params)
// 解决方案info center下载次数
export const infoCenterHitApi = (params) => _axios.post(apiUrl.infoCenterHit, params)

