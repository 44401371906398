<template>
  <div class="options-wrap">
    <template v-if="type === 'check'">
      <public-label
        v-for="(item, index) in options"
        :key="index"
        clickable
        :actived="value.some(i => i[prop.id] === item[prop.id])"
        class-name="option-item"
        @click="handleClick(item)"
      >{{ item[prop.label] }}</public-label>
    </template>
    <template v-else-if="type === 'input'">
      <public-label
        v-for="(item, index) in value"
        :key="index"
        actived
        closable
        class-name="option-item"
        @close="handleClose(item)"
      >{{ item[prop.label] }}</public-label>
      <input v-model="enter" class="enter" placeholder="Enter lanuage" @keyup.enter="handleAdd" @blur="handleAdd" />
    </template>
  </div>
</template>

<script>
import PublicLabel from '@/components/public/PublicLabel'
import emitter from '@/mixin/emitter.js'
export default {
  components: {
    PublicLabel
  },
  mixins: [emitter],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // 双向绑定值
    value: {
      type: Array,
      default: () => []
    },
    // 循环数据
    options: {
      type: Array,
      default: () => []
    },
    // 类型，选择check 或 用户输入input
    type: {
      type: String,
      default: 'check'
    },
    // 显示标签字段名，唯一字段名
    prop: {
      type: Object,
      default: () => ({
        id: 'id',
        label: 'label'
      })
    }
  },
  data() {
    return {
      enter: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        // 双向绑定值变化时触发element校验事件
        this.dispatch('ElFormItem', 'el.form.blur', [])
      },
      deep: true
    }
  },
  methods: {
    // 单项点击后 选中或不选中
    handleClick(item) {
      const _id = item[this.prop.id]
      const _index = this.value.findIndex(i => i[this.prop.id] === _id)
      if (_index > -1) {
        const _value = [...this.value]
        _value.splice(_index, 1)
        this.$emit('change', _value) // 此方法在父组件中不需要接受，只用作组件的v-model双向绑定
      } else {
        this.$emit('change', [...this.value, item])
      }
    },
    // 删除某一项
    handleClose(item) {
      const _value = [...this.value]
      const _index = _value.findIndex(i => i[this.prop.label] === item[this.prop.label])
      _value.splice(_index, 1)
      this.$emit('change', _value)
    },
    // 添加某一项
    handleAdd() {
      if (!this.enter) { // 空不能提交
        return false
      }
      const _value = [...this.value]
      const _index = _value.findIndex(i => i[this.prop.label] === this.enter)
      if (_index > -1) {
        this.$message.error(this.LP.lang_add_again_not)
        return false
      }
      _value.push({ [this.prop.label]: this.enter })
      this.$emit('change', _value)
      this.enter = ''
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.options-wrap {
  font-size: 0;
  .option-item {
    margin-right: 16px;
    margin-bottom: 16px;
    border-radius: 4px;
    /deep/ .label-text {
      color: #909399;
      border-color: #E4E7ED;
    }
  }
  .enter {
    width: 145px;
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
    user-select: none;
    padding: 4px 24px;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    color: #909399;
    border-color: #E4E7ED;
    margin-bottom: 16px;
    vertical-align: bottom;
    .placeholder();
  }
}
</style>
