<template>
  <el-dialog
    :visible="visible"
    v-bind="elementUsed"
    :append-to-body="true"
    v-on="$listeners"
  >
    <template #title>
      <span v-if="elementUsed.title&&displayTitle" class="el-dialog__title">
        {{ elementUsed.title }}
      </span>
      <slot v-if="$slots.title" name="title"></slot>
    </template>
    <div class="close" @click="cancel">
      <img src="@/assets/img/myPage/dialogClose.png" />
    </div>
    <slot></slot>
    <div v-if="showCancel || showSubmit" class="btn-wrap">
      <el-button
        v-if="showCancel"
        type="info"
        class="btn"
        @click="cancel"
      >{{ LP.lang_cancel }}</el-button>
      <el-button
        v-if="showSubmit"
        type="primary"
        class="btn"
        :loading="loading"
        @click="submit"
      >{{ LP.lang_submit }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    // 弹窗是否显示
    displayTitle: {
      type: Boolean,
      default: true
    },
    // 弹窗是否显示
    visible: {
      type: Boolean,
      default: false
    },
    // 是否显示cancel按钮
    showCancel: {
      type: Boolean,
      default: true
    },
    // 是否显示submit按钮
    showSubmit: {
      type: Boolean,
      default: true
    },
    // 是否loading
    loading: {
      type: Boolean,
      default: false
    },
    // element dialog自带属性
    elementConfig: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      elementConfigDefault: {
        title: 'Notice',
        width: '1080px',
        'custom-class': 'public-dialog-container',
        'show-close': false
      }
    }
  },
  computed: {
    elementUsed() {
      return {
        ...this.elementConfigDefault,
        ...this.elementConfig
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/styles/common/common.less';
/deep/ .public-dialog-container {
  max-width: 92% !important;
  border-radius: 4px;
  .el-dialog__header {
    overflow: hidden;
    padding: 0;
    .el-dialog__title {
      padding: 40px 72px 0 72px;
      text-align: center;
      display: block;
      font-size: 24px;
      font-weight: 600;
      color: #303133;
      line-height: 32px;
    }
  }
  .el-dialog__body {
    padding: 44px 72px 40px;
    font-size: 18px;
    color: #606266;
    line-height: 24px;
    .close {
      position: absolute;
      top: -45px;
      right: -45px;
      cursor: pointer;
      img {
        height: 45px;
        width: 45px;
      }
      .transition();
      &:hover {
        transform: rotate(180deg);
      }
    }
    .btn-wrap {
      text-align: center;
      padding-top: 23px;
      .btn {
        padding: 9px 20px;
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 20px;
        border-radius: 4px;
        & + .btn {
          margin-left: 30px;
        }
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .el-dialog__header {
      .el-dialog__title {
        padding: 30px 60px 0 60px;
        font-size: 20px;
        line-height: 32px;
      }
    }
    .el-dialog__body {
      padding: 30px 60px 30px;
      font-size: 16px;
      line-height: 24px;
      .close {
        right: 0;
        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .el-dialog__header {
      .el-dialog__title {
        padding: 15px 20px 0 20px;
        font-size: 18px;
        line-height: 26px;
      }
    }
    .el-dialog__body {
      padding: 15px 20px 15px;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
</style>
